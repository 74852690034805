$(function(){
  $('.page .name').each(function(){
    var cnt = $(this).text().length;
    var max = $(this).data("max");
    if(cnt>max)$(this).css('font-size',2.6*max/cnt+"rem");
  });

  $('.filter input[type="radio"]').on('change',function(){
    $(this).parents('form').submit();
  });

  $('.paging_num select').on('change',function(){
    $(this).parents('form').submit();
  });

  $('.delivery_state-tabs input[type="radio"]').on('change',function(){
    $(this).parents('form').submit();
  });

  $('.clear_input').on('click', function(){
    $(this).prevAll('[name="keyword"]').val('');
  });

  $('.tasks input[type="radio"]').on('click',function(){
    $form = $(this).parents('form');
    var tmp = $form.attr("action");
    $form.attr("action",$(this).val());
    //if($form.attr("action").match(/csv/) == null)$form.attr("target","_blank");
    $form.attr("target","_blank");
    $form.submit();
    $form.attr("action",tmp);
    $form.removeAttr("target","_blank");
  });

  $.datetimepicker.setLocale('ja');
  $('.datetimepicker').datetimepicker({
    format: 'Y-m-d H:i:s',
    timepicker: false,
    defaultTime: '00:00',
    scrollInput: false,
    scrollMonth: false,
    scrollTime: false,
    minDate: 0
  });

  $('input[name="all"]').on('change',function(){
    $('input[name="orders[]"]').prop('checked',$(this).prop('checked'));
  });

  $.ajaxSetup({
    headers: {
      'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
  });

  $('.ajax input').on('change',function(){
    $.ajax({
      url: $(this).parent().data('url'),
      type: 'post',
      data: $(this).attr('name')+"="+$(this).val(),
    }).done(function(data){
      console.log(data);
    });
  });

  $('.mail_templates_filter').on('change','select',function(){
    $('.mail_templates_filter').submit();
  });

  $('.select.ajax select').on('change',function(){
    $.ajax({
      url: $(this).parent().data('url'),
      type: 'post',
      data: $(this).attr('name')+"="+$(this).val(),
    }).done(function(data){
      console.log(data);
    });
  });

  $('.products_table').on('click','.remove',function(e){
    $(this).parent().parent().remove();
    if($(this).attr('data-id')!=undefined){
      $('.removeproducts').append('<input type="hidden" name="remove[]" value="'+$(this).data('id')+'">');
    }
    e.preventDefault();
  });
  var max_product = 1;
  $('.add_product').on('click',function(e){
    $(this).parent().parent().before('<tr><td></td><td></td><td></td><td><input name="order_products[new'+max_product+'][jan]" type="text"></td><td><input name="order_products[new'+max_product+'][product_name]" type="text"></td><td class="price"><input name="order_products[new'+max_product+'][price]" type="text">円</td><td class="quantity"><input name="order_products[new'+max_product+'][quantity]" type="text">個</td><td><input name="order_products[new'+max_product+'][tax_rate]" type="text"></td><td><div class="button remove">削除</div></td></tr>');
    max_product++;
    e.preventDefault();
  });

  $("input, select, textarea").attr("autocomplete", "off");
});